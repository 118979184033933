<template>
  <b-card no-body>
    <b-card-body class="text-right">
      <b-button
        variant="primary"
        :to="$route.path + '/import'"
      >
        <FeatherIcon icon="UploadIcon" /> Data Aktarım
      </b-button>
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(ads_id)="data">
        <div
          v-if="data.item.ads_id"
          class="text-muted font-small-2"
        >
          {{ data.item.ads_id }}
        </div>
        <div
          v-else
          class="text-muted font-small-2"
        >
          -
        </div>
      </template>
      <template #cell(customer)="data">
        <div>
          {{ data.item.customer }}
        </div>
        <div class="text-primary font-small-2">
          {{ data.item.phone }}
        </div>
        <div class="text-primary font-small-2">
          {{ data.item.email }}
        </div>
      </template>
      <template #cell(created)="data">
        {{ moment(data.item.created).format('DD.MM.YYYY') }}
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="primary"
            size="sm"
            :to="'/interviews/view/' + data.item.interview_number"
            target="_blank"
          >
            <FeatherIcon icon="EyeIcon" />
            Görüntüle
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
      <div class="text-muted">
        Toplam {{ dataCounts | toNumber }} adet kayıt bulundu.
      </div>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BPagination, BCardFooter, BCardBody, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const tableName = 'com_marketing_interview'
export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BCardBody,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'ads_id',
          label: 'REKLAM ID',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'customer',
          label: 'MÜŞTERİ',
        },
        {
          key: 'created',
          label: 'Aktarım Tarihi',
          thClass: 'width-100 text-nowrap text-center',
          tdClass: 'width-100 text-nowrap text-center',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.customer_number AS customer_number`,
          `${tableName}.interview_number AS interview_number`,
          `${tableName}.ads_id AS ads_id`,
          `${tableName}.id_com_customer AS id_com_customer`,
          `${tableName}.id_com_user AS id_com_user`,
          `${tableName}.created AS created`,
          'com_customer.name AS customer',
          'com_customer.phone AS phone',
          'com_customer.email AS email',
          'com_user.name AS username',
        ],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['marketingBatchInterviews/dataList']
    },
    dataCounts() {
      return this.$store.getters['marketingBatchInterviews/dataCounts']
    },
    saveData() {
      return this.$store.getters['marketingBatchInterviews/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('marketingBatchInterviews/getDataList', this.dataQuery)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
